import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import html2canvas from "html2canvas";
import { AzureCommunicationTokenCredential } from "@azure/communication-common";
import { FluentThemeProvider } from "@azure/communication-react";
import { initializeIcons, registerIcons, Stack } from "@fluentui/react";

import {
  DEFAULT_COMPONENT_ICONS,
  CallClientProvider,
  CallAgentProvider,
  CallProvider,
  createStatefulCallClient,
  createStatefulChatClient,
  ChatClientProvider,
  ChatThreadClientProvider,
} from "@azure/communication-react";

import { useIsMobile } from "../../Utilities/useIsMobile";
import CallingComponents from "./VideoCallingComponentsStateful";
import ChatComponents from "./ChatComponentsStateful";
import LoadingUI from "../CustomButton/LoadingUI";
import {
  sendMessage,
  fetchTokenForRecording,
  stopRecordingResponse,
  pauseRecordingResponse,
  resumeRecordingResponse,
} from "../../Utilities/useRequest";

initializeIcons();
registerIcons({ icons: DEFAULT_COMPONENT_ICONS });
const stackStyle = {
  root: {
    width: "100%",
    height: "100%",
  },
};

export default function MeetingRoom(props) {
  const isMobile = useIsMobile();
  const [isScreenShot, setIsScreenShot] = useState(false);
  const [isChat, setIsChat] = useState(!isMobile);
  const [recordingId, setRecordingId] = useState(null);
  const [isRecording, setRecording] = useState(false);
  const [isRecordingPause, setRecordingPause] = useState(false);
  const [recordingButtonState, setRecordingButtonState] = useState("start");
  const [recordingButtonDisabled, setRecordingButtonDisabled] = useState(false);

  //  Custom Function
  const handleChatWindow = () => {
    setIsChat(!isChat);
  };

  const handleRecordingButton = (action) => {
    setRecordingButtonDisabled(true);
    let notificationMessage = {
      adminUserId: props.userId,
      threadId: props.threadId,
      displayName: props.displayName,
      messageContent:
        action === "start" || action === "resume"
          ? "Recording Started"
          : "Recording Stopped",
    };
    if (action === "start") {
      fetchTokenForRecording(serverCallId).then((response) => {
        setRecordingId(response.result.recordingId);
        setRecordingButtonState("pause");
        sendMessage(notificationMessage);
        setRecordingButtonDisabled(false);
      });
    }
    if (action === "pause" && recordingId) {
      pauseRecordingResponse(recordingId).then(() => {
        setRecordingButtonState("resume");
        sendMessage(notificationMessage);
        setRecordingButtonDisabled(false);
      });
    }
    if (action === "resume" && recordingId) {
      resumeRecordingResponse(recordingId).then(() => {
        setRecordingButtonState("pause");
        sendMessage(notificationMessage);
        setRecordingButtonDisabled(false);
      });
    }
  };

  const handleCallEndRequest = () => {
    setRecordingButtonDisabled(true);
    if (recordingButtonState === "pause") {
      stopRecordingResponse(recordingId).then(() => {
        setRecordingButtonState("stop");
        setRecordingButtonDisabled(false);
        props.handleDisconnect();
      });
    } else if (recordingButtonState === "resume") {
      resumeRecordingResponse(recordingId).then(() => {
        stopRecordingResponse(recordingId).then(() => {
          setRecordingButtonState("stop");
          setRecordingButtonDisabled(false);
          props.handleDisconnect();
        });
      });
    } else {
      props.handleDisconnect();
    }
  };

  const handleRecordingNotificationState = (state) => {
    if (state === "Started" && !isRecording) {
      setRecording(true);
    } else if (state === "Stopped" && isRecording) {
      setRecording(false);
      setRecordingPause(false);
    } else if (state === "Paused" && !isRecordingPause) {
      setRecordingPause(true);
    } else if (state === "Resumed" && isRecordingPause) {
      setRecordingPause(false);
    }
  };

  const handleScreenshot = (divRef) => {
    if (divRef.current) {
      setIsScreenShot(!isScreenShot);
      html2canvas(divRef.current).then((canvas) => {
        const screenshotImage = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.href = screenshotImage;
        downloadLink.download = "screenshot.png";
        downloadLink.click();
        setIsScreenShot(!isScreenShot);
      });
    }
  };

  // Communication Details
  const userAccessToken = props.userAccessToken;
  const userId = props.userId;
  const groupId = props.groupId;
  const displayName = props.displayName;

  const [statefulCallClient, setStatefulCallClient] = useState();
  const [callAgent, setCallAgent] = useState();
  const [call, setCall] = useState();
  const [serverCallId, setServerCallId] = useState();

  const endpointUrl =
    "https://heeliocommunication.unitedstates.communication.azure.com/";
  const threadId = props.threadId;
  const tokenCredential = new AzureCommunicationTokenCredential(
    userAccessToken
  );

  // *********** Chat ***********
  // Instantiate the statefulChatClient
  const statefulChatClient = createStatefulChatClient({
    userId: { communicationUserId: userId },
    displayName: displayName,
    endpoint: endpointUrl,
    credential: tokenCredential,
  });

  // Listen to notifications
  statefulChatClient.startRealtimeNotifications();
  const chatThreadClient = statefulChatClient.getChatThreadClient(threadId);

  useEffect(() => {
    async function initializeThreadState() {
      await chatThreadClient.getProperties();
    }
    initializeThreadState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatThreadClient]);

  // *********** Video ***********
  useEffect(() => {
    const statefulCallClient = createStatefulCallClient({
      userId: { communicationUserId: userId },
    });
    statefulCallClient.getDeviceManager().then((deviceManager) => {
      deviceManager.askDevicePermission({ video: true, audio: true });
    });
    setStatefulCallClient(statefulCallClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (callAgent === undefined && statefulCallClient && displayName) {
      const createUserAgent = async () => {
        setCallAgent(
          await statefulCallClient.createCallAgent(tokenCredential, {
            displayName: displayName,
          })
        );
      };
      createUserAgent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statefulCallClient, displayName, callAgent]);

  useEffect(() => {
    if (callAgent !== undefined) {
      setCall(callAgent.join({ groupId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callAgent]);

  return (
    <FluentThemeProvider>
      <Stack horizontal horizontalAlign="space-between" styles={stackStyle}>
        <div className="hcs-bg-light" style={{ height: "100%", width: "100%" }}>
          {statefulCallClient && (
            <CallClientProvider callClient={statefulCallClient}>
              {callAgent ? (
                <CallAgentProvider callAgent={callAgent}>
                  {call && (
                    <CallProvider call={call}>
                      <CallingComponents
                        {...props}
                        roomId={groupId}
                        isMobile={isMobile}
                        orderId={props.orderId}
                        isChat={isChat}
                        isScreenShot={isScreenShot}
                        recordingButtonState={recordingButtonState}
                        recordingButtonDisabled={recordingButtonDisabled}
                        serverCallId={serverCallId}
                        handleServerCallId={setServerCallId}
                        handleChatWindow={handleChatWindow}
                        handleScreenshot={handleScreenshot}
                        handleCallEndRequest={handleCallEndRequest}
                        handleRecordingButton={handleRecordingButton}
                      />
                    </CallProvider>
                  )}
                </CallAgentProvider>
              ) : (
                <div
                  className="p-4"
                  style={{ width: "100vw", height: "100vh" }}
                >
                  <LoadingUI />
                </div>
              )}
            </CallClientProvider>
          )}
        </div>

        {isChat && statefulCallClient && callAgent && isMobile && (
          <Modal show={isChat} fullscreen={true}>
            <div
              className="hcs-bg-primary-light"
              style={{ height: "100dvh", minWidth: "100%" }}
            >
              <ChatClientProvider chatClient={statefulChatClient}>
                <ChatThreadClientProvider chatThreadClient={chatThreadClient}>
                  <ChatComponents
                    {...props}
                    isMobile={isMobile}
                    handleChatWindow={handleChatWindow}
                    handleRecordingNotificationState={
                      handleRecordingNotificationState
                    }
                  />
                </ChatThreadClientProvider>
              </ChatClientProvider>
            </div>
          </Modal>
        )}
        {isChat && statefulCallClient && callAgent && !isMobile && (
          <div
            className="hcs-bg-primary-light"
            style={{ height: "100dvh", width: "100%", maxWidth: "300px" }}
          >
            <ChatClientProvider chatClient={statefulChatClient}>
              <ChatThreadClientProvider chatThreadClient={chatThreadClient}>
                <ChatComponents
                  {...props}
                  isMobile={isMobile}
                  handleChatWindow={handleChatWindow}
                  handleRecordingNotificationState={
                    handleRecordingNotificationState
                  }
                />
              </ChatThreadClientProvider>
            </ChatClientProvider>
          </div>
        )}
      </Stack>
    </FluentThemeProvider>
  );
}

import { v1 as generateGUID } from "uuid";

export const isOnIphoneAndNotSafari = () => {
  const userAgent = navigator.userAgent;
  return (
    userAgent.includes("iPhone") &&
    (userAgent.includes("FxiOS") || userAgent.includes("CriOS"))
  );
};

export const isLandscape = () => window.innerWidth < window.innerHeight;
export const navigateToHomePage = () => {
  window.location.href = window.location.href.split("?")[0];
};

export const createGroupId = () => ({ groupId: generateGUID() });

export const getTokenFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  return token ? token : null;
};

export const getIdFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const guid = urlParams.get("group");
  const thid = urlParams.get("thread");
  const adminuid = urlParams.get("uid");
  const orderId = urlParams.get("order_id") || null;
  console.log(orderId);
  if (guid && thid && adminuid) {
    return {
      threadId: thid,
      adminId: adminuid,
      groupId: guid,
      orderId: orderId,
    };
  } else {
    return null;
  }
};

export const updateURLWithParams = (paramsObject) => {
  let originalURL =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;

  let params = new URLSearchParams(window.location.search);
  for (let key in paramsObject) {
    if (paramsObject.hasOwnProperty(key)) {
      params.set(key, paramsObject[key]);
    }
  }

  let updatedURL = `${originalURL}?${params.toString()}`;
  return updatedURL;
};

export const generateRandomCode = () => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 6; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const handleRecordingNotificationMessage = (data, userId) => {
  const messages = data && data.messages;
  let recordingNotificationMessages =
    messages &&
    messages.filter(
      (d) =>
        d.content &&
        [
          "Recording Started",
          " Recording Stopped",
          " Recording Resumed",
          "Recording Paused",
        ].includes(d.content) &&
        d.senderId === userId
    );
  if (recordingNotificationMessages.length > 0) {
    const requestArray = recordingNotificationMessages.map(
      (d) => d.content.split(" ")[1]
    );
    let requestType = requestArray[requestArray.length - 1];
    return requestType;
  } else {
    return null;
  }
};

import React from "react";
import { CustomButton } from "./CustomButtom";

export const ChatButton = ({ disabled, handleButtonClick, status }) => {
  const buttonLabel = status ? "Show Chat Window" : "Hide Chat Window";
  return (
    <CustomButton
      handleButtonClick={handleButtonClick}
      iconName="OfficeChatSolid"
      buttonLabel={buttonLabel}
      text="Chat"
      color={status ? "#a19f9d" : "#323130"}
      disabled={disabled}
    />
  );
};

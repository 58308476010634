import React from "react";
import { CustomButton } from "./CustomButtom";

export const RecordingButton = ({
  handleButtonClick,
  recordingButtonState,
  recordingButtonDisabled,
}) => {
  const buttonLabel =
    recordingButtonState === "pause" ? "Stop Recording" : "Start Recording";
  const color = recordingButtonState === "pause" ? "red" : "#323130";
  const iconName =
    recordingButtonState === "pause" ? "CircleShapeSolid" : "RadioBtnOn";

  return (
    <CustomButton
      color={color}
      disabled={recordingButtonDisabled}
      text={buttonLabel}
      iconName={iconName}
      buttonLabel={buttonLabel}
      handleButtonClick={() => handleButtonClick(recordingButtonState)}
    />
  );
};

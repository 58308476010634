import { useState, useEffect } from "react";
import { WebPubSubClient } from "@azure/web-pubsub-client";
import axios from "axios";

export const useWebSocket = () => {
  const mode = process.env.REACT_APP_MODE || "local"; // Default to PROD if REACT_APP_MODE is not set
  const baseUrlDev = "http://127.0.0.1:5000/api/";
  const baseUrlProd = "https://heelio-server.azurewebsites.net/api/";
  const baseUrl = mode === "local" ? baseUrlDev : baseUrlProd;

  const postRequest = (urlExtension, data) => {
    const headers = {
      "Content-Type": "application/json",
    };
    return new Promise((resolve, reject) => {
      axios
        .post(baseUrl + urlExtension, data, { headers: headers })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
  const handleSocketJoinRoom = (data) => {
    return new Promise((resolve, reject) => {
      postRequest("socket/join_room", data)
        .then((response) => {
          resolve(response.url);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const [isConnected, setIsConnected] = useState(false);
  const [roomMessage, setMessages] = useState({});
  const [client, setClient] = useState(null);

  useEffect(() => {
    if (client && isConnected) {
      client.on("group-message", (e) => {
        try {
          const messageObject = JSON.parse(e.message.data);
          setMessages(messageObject.content.data);
        } catch (error) {
          console.error("Error parsing message JSON:", error);
        }
      });
    }
    return () => {
      if (client) {
        client.on("stopped", () => {
          setIsConnected(false);
          console.log(`Client has stopped`);
        });
      }
    };
    // eslint-disable-next-line
  }, [isConnected]);

  const joinRoom = (newRoom) => {
    if (client && newRoom) {
      client.joinGroup(newRoom);
      console.log(`Joined new room: ${newRoom}`);
    }
  };

  const leaveRoom = async (oldRoom) => {
    if (client && oldRoom) {
      client.stop();
    }
  };

  const connect = async (url, targetRoom) => {
    try {
      const webClient = new WebPubSubClient(url);
      await webClient.start();
      webClient.joinGroup(targetRoom).then(() => {
        setClient(webClient);
        setIsConnected(true);
      });
    } catch (error) {
      console.error("Failed to initialize the WebSocket client:", error);
    }
  };

  const sendMessage = async (data, targetRoom) => {
    if (client && data) {
      const messageJson = JSON.stringify({
        content: data,
      });
      client.sendToGroup(targetRoom, messageJson, "text");
      console.log(`Message sent to ${targetRoom}`);
    }
  };

  return {
    isConnected,
    roomMessage,
    joinRoom,
    leaveRoom,
    sendMessage,
    connect,
    handleSocketJoinRoom,
  };
};

import {
  usePropsFor,
  MessageThread,
  SendBox,
} from "@azure/communication-react";
import React from "react";
import { mergeStyles, Stack } from "@fluentui/react";
import CopyToClipboardButton from "../CustomButton/CopyToClipboard";

function ChatComponents(props) {
  const messageThreadProps = usePropsFor(MessageThread);
  const sendBoxProps = usePropsFor(SendBox);
  const sendBoxStyles = {
    root: {
      borderRadius: "10px",
      width: "inherit",
    },
    textField: { background: "ghostwhite" },
  };
  const messageThreadStyles = {
    chatContainer: {
      padding: "15px",
      borderRadius: "10px",
      backgroundColor: "inherit",
    },
  };

  return (
    <Stack
      className={mergeStyles({
        height: "100%",
        width: "100%",
        paddingRight: props.isMobile ? ".5em" : null,
        paddingLeft: props.isMobile ? ".5em" : null,
      })}
    >
      {" "}
      <>
        {props.isMobile ? (
          <div className="p-2 text-white d-flex flex-row justify-content-between border-bottom border-white">
            <div className="fw-semibold">Chat </div>
            <i
              className="bi bi-x-lg hcs-pointer"
              onClick={props.handleChatWindow}
            ></i>
          </div>
        ) : null}
      </>
      {props.isAdmin && props.inviteLink && !props.inviteStatus ? (
        <>
          <div className="p-2 text-white d-flex flex-row justify-content-between">
            <div className="fw-semibold">Invite</div>
            <CopyToClipboardButton textToCopy={props.inviteLink} text="Link" />
          </div>
          <div className="input-group input-group-sm px-1">
            <input
              type="email"
              id="email"
              value={props.email ? props.email : ""}
              className="form-control bg-white text-dark"
              placeholder="Participant's Email"
              aria-label="email"
              aria-describedby="button-addon2"
              onChange={props.handleInput}
            />
            <button
              className="btn btn-secondary w-25"
              type="button"
              id="button-addon2"
              onClick={props.handleInvite}
              disabled={props.inviteStatus}
            >
              Send
            </button>
          </div>
        </>
      ) : props.isAdmin ? (
        <div className="text-warning p-2">Sending Email..</div>
      ) : null}
      <div className="flex-grow-1 pt-2 px-1 hcs-bg-primary-light overflow-y-scroll">
        {messageThreadProps && (
          <MessageThread {...messageThreadProps} styles={messageThreadStyles} />
        )}
      </div>
      {sendBoxProps && <SendBox {...sendBoxProps} styles={sendBoxStyles} />}
    </Stack>
  );
}

export default ChatComponents;

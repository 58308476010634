import axios from "axios";

const mode = process.env.REACT_APP_MODE;
const serverUrl = "https://heelio-communication-services.azurewebsites.net";
const localhost = "http://127.0.0.1:5000";

const domainUrl = mode === "local" ? localhost : serverUrl;
const url = `${domainUrl}/video`;
const url1 = `${domainUrl}/chat`;
const url2 = `${domainUrl}/email`;
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer YourAccessToken",
};

export const fetchTokenResponse = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${url}/create-user-token`, { headers });
      if (response.data.status) {
        resolve(response.data);
      } else {
        reject(new Error("Invalid response status"));
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const revokeTokenResponse = (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${url}/revoke-token`,
        { user_id: userId },
        { headers }
      );

      if (response.data.status) {
        resolve(response.data);
      } else {
        reject(new Error("Invalid response status"));
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const refreshTokenResponse = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.get(`${url}/refresh-token`, { headers });

      if (response.data.status) {
        resolve(response.data);
      } else {
        reject(new Error("Invalid response status"));
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const fetchTokenForRecording = (server_call_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${url}/start-recording`,
        { server_call_id: server_call_id },
        { headers }
      );

      if (response.data.status) {
        resolve(response.data);
      } else {
        reject(new Error("Invalid response status"));
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const stopRecordingResponse = (recording_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${url}/stop-recording`,
        { recording_id: recording_id },
        { headers }
      );

      if (response.data.status) {
        resolve(response.data);
      } else {
        reject(new Error("Invalid response status"));
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const pauseRecordingResponse = (recording_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${url}/pause-recording`,
        { recording_id: recording_id },
        { headers }
      );

      if (response.data.status) {
        resolve(response.data);
      } else {
        reject(new Error("Invalid response status"));
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const resumeRecordingResponse = (recording_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${url}/resume-recording`,
        { recording_id: recording_id },
        { headers }
      );

      if (response.data.status) {
        resolve(response.data);
      } else {
        reject(new Error("Invalid response status"));
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const captureScreenshotResponse = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        `${url}/resume-recording`,
        { data: data },
        { headers }
      );

      if (response.data.status) {
        resolve(response.data);
      } else {
        reject(new Error("Invalid response status"));
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const createChatRoom = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${url1}/create-chat-room`, data, {
        headers,
      });

      if (response.data.status) {
        resolve(response.data);
      } else {
        console.log(new Error("Invalid response status"));
        reject(response.data);
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const joinChatRoom = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${url1}/join-chat-room`, data, {
        headers,
      });

      if (response.data.status) {
        resolve(response.data);
      } else {
        console.log(new Error("Invalid response status"));
        reject(response.data);
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const sendEmailNotification = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${url2}/send-notification`, data, {
        headers,
      });

      if (response.data.status) {
        resolve(response.data);
      } else {
        console.log(new Error("Invalid response status"));
        reject(response.data);
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const sendMessage = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(`${url1}/send-message`, data, {
        headers,
      });

      if (response.data.status) {
        resolve(response.data);
      } else {
        console.log(new Error("Invalid response status"));
        reject(response.data);
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

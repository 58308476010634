import { IconButton, TooltipHost } from "@fluentui/react";
import React from "react";

const btnStyles = {
  height: "56px",
  width: "56px",
  border: "0px none rgb(32, 31, 30)",
  borderTop: "0px none rgb(32, 31, 30)",
  borderRight: "0px none rgb(32, 31, 30)",
  borderBottom: "0px none rgb(32, 31, 30)",
  borderLeft: "0px none rgb(32, 31, 30)",
  margin: "0px",
  padding: "0px 16px",
  maxHeight: "none",
  minHeight: "56px",
  maxWidth: "128px",
  minWidth: "56px",
};

export const CustomButton = ({
  handleButtonClick,
  iconName,
  buttonLabel,
  text,
  color,
  disabled,
}) => {
  return (
    <TooltipHost content={buttonLabel}>
      <IconButton
        iconProps={{
          iconName: iconName,
        }}
        text={text}
        onClick={handleButtonClick}
        label={buttonLabel}
        style={{
          ...btnStyles,
          color: color,
        }}
        disabled={disabled}
      />
    </TooltipHost>
  );
};

import React, { useState } from "react";

const CopyToClipboardButton = ({ textToCopy, text }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, 1000);
    } catch (error) {
      console.error("Failed to copy text to clipboard", error);
    }
  };

  return (
    <div
      className="text-dark text-center  text-white hcs-pointer"
      onClick={copyToClipboard}
    >
      {isCopied ? (
        <span>
          Copied <i className="bi bi-clipboard-check-fill ps-2"></i>
        </span>
      ) : (
        <span>
          {text ? text : "Invite Link"}
          <i className="bi bi-copy ps-2"></i>
        </span>
      )}
    </div>
  );
};

export default CopyToClipboardButton;

import React, { useEffect, useState } from "react";
import "./App.css";

import Main from "./Elements/Pages/Main";
import MeetingRoom from "./Elements/VideoChat/MeetingRoom";

import {
  fetchTokenResponse,
  createChatRoom,
  joinChatRoom,
  sendEmailNotification,
} from "./Utilities/useRequest";

import {
  createGroupId,
  getIdFromUrl,
  updateURLWithParams,
  getTokenFromUrl,
} from "./Utilities/Utils";

function App() {
  const [roomStatus, setRoomStatus] = useState({ chat: false, video: false });
  const [email, setEmail] = useState(null);
  const [code, setCode] = useState(null);
  const [isAdmin, setIsAdmin] = useState(true);

  const [orderId, setOrderId] = useState(null);
  const [userAccessToken, setUserAccessToken] = useState("");
  const [adminUserId, setAdminUserId] = useState("");
  const [userId, setUserId] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [threadId, setThreadId] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [connect, setConnect] = useState(false);

  const [isLoading, setIsloading] = useState(false);
  const [error, setError] = useState({ status: false, message: "" });
  const [inviteLink, setInviteLink] = useState(null);
  const [inviteStatus, setInviteStatus] = useState(false);

  const handleInput = (e) => {
    setError({ status: false, message: "" });
    if (e.target.id === "userAccessToken") {
      setUserAccessToken(e.target.value);
    } else if (e.target.id === "displayName") {
      setDisplayName(e.target.value);
    } else if (e.target.id === "userId") {
      setUserId(e.target.value);
    } else if (e.target.id === "groupId") {
      setGroupId(e.target.value);
    } else if (e.target.id === "email") {
      setEmail(e.target.value);
    } else if (e.target.id === "code") {
      setCode(e.target.value);
    }
  };

  const handleInviteUrl = () => {
    if (isAdmin) {
      setInviteStatus(true);
      let params = {
        thread: threadId,
        uid: userId,
        group: groupId,
      };
      let data = {
        inviteLink: updateURLWithParams(params),
        displayName: displayName,
        type: "videochat",
        recipients_to: [
          {
            address: email,
          },
        ],
      };
      try {
        email &&
          sendEmailNotification(data).then((response) => {
            setInviteLink(() => {
              let updatedURL = response.result;
              setInviteStatus(false);
              setEmail(null);
              return updatedURL;
            });
          });
      } catch (error) {
        setInviteStatus(false);
        console.error(error);
      }
    }
  };

  const handleConnect = async () => {
    try {
      if (!displayName) {
        setError({ status: true, message: "Name Value is Missing" });
        return;
      }
      if (isAdmin && !email) {
        setError({ status: true, message: "Email Address is Missing." });
        return;
      }

      if (!code) {
        setError({ status: true, message: "Access key is missing." });
        return;
      }
      if (isAdmin && code !== "LAMAS2024") {
        setError({ status: true, message: "Invalid Access key" });
        return;
      }
      if (!isAdmin) {
        //  Need to change the logic
        // let tokenStatus = tokenValidator.isTimestampValid(code);
        // let urlToken = getTokenFromUrl();
        // if (!tokenStatus || (urlToken && urlToken !== code)) {
        //   setError({ status: true, message: "Invalid Code or Expired Url" });
        //   return;
        // }

        let urlToken = getTokenFromUrl();
        if (urlToken && urlToken !== code) {
          setError({ status: true, message: "Invalid Code or Expired Url" });
          return;
        }

        if (threadId === null || adminUserId === null || groupId === null) {
          setError({ status: true, message: "Invalid Invite Url" });
          return;
        }
      }
      setIsloading(true);
      const response = await fetchTokenResponse();
      if (!response.status) {
        setError({
          status: false,
          message: response.message,
        });
        return;
      } else if (response.status) {
        setUserAccessToken(response.result.token);
        setUserId(response.result.identity);
        if (response.result.token && response.result.identity) {
          setRoomStatus((prev) => ({ ...prev, video: true }));
        }
      }

      if (threadId === null) {
        const response2 = await createChatRoom({
          adminUserId: response.result.identity,
          topic: "Demo",
        });
        if (response2.status) {
          setThreadId(response2.result.thread_id);
          setRoomStatus((prev) => ({ ...prev, chat: true }));
        } else {
          setError({
            status: false,
            message: response2.message,
          });
        }
      } else {
        let data = {
          adminUserId: adminUserId,
          threadId: threadId,
          userId: response.result.identity,
          displayName: displayName,
        };
        const response3 = await joinChatRoom(data);
        if (!response3.status) {
          setError({
            status: false,
            message: response3.message,
          });
        } else {
          setRoomStatus((prev) => ({ ...prev, chat: true }));
        }
      }

      if (groupId === null) {
        setGroupId(() => {
          let temp = createGroupId();
          return temp.groupId;
        });
      }

      if (!error.status) {
        setIsloading(false);
      }
    } catch (error) {
      setIsloading(false);
      setError({
        status: false,
        message: error.message || "An error occurred",
      });
    }
  };

  const handleDisconnect = () => {
    window.location.reload();
  };

  useEffect(() => {
    let response = getIdFromUrl();
    if (response) {
      setGroupId(response.groupId);
      setThreadId(response.threadId);
      setAdminUserId(response.adminId);
      setOrderId(response.orderId);
      setIsAdmin(false);
    } else {
      setIsAdmin(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      roomStatus.video &&
      roomStatus.chat &&
      groupId &&
      userId &&
      threadId &&
      userAccessToken
    ) {
      setConnect(true);
      handleInviteUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomStatus, threadId, groupId, userId, userAccessToken]);

  if (connect) {
    return (
      <MeetingRoom
        room="videochat"
        email={email}
        isAdmin={isAdmin}
        adminUserId={adminUserId}
        userAccessToken={userAccessToken}
        userId={userId}
        groupId={groupId}
        threadId={threadId}
        orderId={orderId}
        displayName={displayName}
        inviteLink={inviteLink}
        inviteStatus={inviteStatus}
        handleDisconnect={handleDisconnect}
        handleInput={handleInput}
        handleInvite={handleInviteUrl}
      />
    );
  } else {
    return (
      <Main
        room="videochat"
        email={email}
        code={code}
        isAdmin={isAdmin}
        displayName={displayName}
        isLoading={isLoading}
        handleConnect={handleConnect}
        handleInput={handleInput}
        error={error}
      />
    );
  }
}

export default App;

import { useEffect, useState } from "react";
import MobileDetect from "mobile-detect";

const detectMobileSession = () =>
  !!new MobileDetect(window.navigator.userAgent).mobile();

export const useIsMobile = () => {
  const [isMobileSession, setIsMobileSession] = useState(detectMobileSession());
  useEffect(() => {
    const updateIsMobile = () => {
      setTimeout(() => {
        setIsMobileSession(detectMobileSession());
      });
    };

    window.addEventListener("resize", updateIsMobile);
    return () => window.removeEventListener("resize", updateIsMobile);
  }, []);

  return isMobileSession;
};

import { DevicesButton } from "@azure/communication-react";
import React from "react";

export const MirrorButton = (props) => {
  const mirroOptions = {
    items: [
      {
        key: "1",
        name: "Mirror Local Video",
        iconProps: { iconName: "ColumnVerticalSection" },
        onClick: () => props.handleLocal(),
      },
      {
        key: "2",
        name: "Mirror Remote Video",
        iconProps: { iconName: "ColumnVerticalSection" },
        onClick: () => props.handleRemote(),
      },
    ],
  };

  return <DevicesButton key={"optionsCustomBtnKey"} menuProps={mirroOptions} />;
};

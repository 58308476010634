import React from "react";
import { CustomButton } from "./CustomButtom";

export const ScreenShotButton = ({ status, handleButtonClick, disabled }) => {
  const buttonLabel = "Screen Shot";
  return (
    <CustomButton
      handleButtonClick={handleButtonClick}
      iconName="BrowserScreenShot"
      buttonLabel={buttonLabel}
      text={buttonLabel}
      color={status ? "#a19f9d" : "#323130"}
      disabled={disabled}
    />
  );
};

import React, { useRef, useState, useEffect } from "react";

import {
  usePropsFor,
  VideoGallery,
  DevicesButton,
  CameraButton,
  MicrophoneButton,
  ScreenShareButton,
  RaiseHandButton,
  EndCallButton,
  useCall,
} from "@azure/communication-react";
import { v4 as uuidv4 } from "uuid";
import { useWebSocket } from "../../Utilities/useSocket";

// custom button
import { ChatButton } from "../CustomButton/ChatButton";
import { ScreenShotButton } from "../CustomButton/ScreenShotButton";
import { MirrorButton } from "../CustomButton/MirrorButton";
import { RecordingButton } from "../CustomButton/RecordingButton";

function CallingComponents(props) {
  const [userId, setUserId] = useState(null);
  const {
    isConnected,
    roomMessage,
    sendMessage,
    connect,
    leaveRoom,
    handleSocketJoinRoom,
  } = useWebSocket();

  const divRef = useRef(null);
  const videoGalleryProps = usePropsFor(VideoGallery);
  const cameraProps = usePropsFor(CameraButton);
  const microphoneProps = usePropsFor(MicrophoneButton);
  const screenShareProps = usePropsFor(ScreenShareButton);
  const endCallProps = usePropsFor(EndCallButton);
  const raiseHandProps = usePropsFor(RaiseHandButton);
  const deviceProps = usePropsFor(DevicesButton);

  // eslint-disable-next-line
  const [intialCamera, setInitialCamera] = useState(null);
  const [currentCamera, setCurrentCamera] = useState(null);
  const [localVideoViewOptions, setLocalVideoViewOptions] = useState({
    scalingMode: "Crop", //Fit,Crop
    isMirrored: true,
  });
  // eslint-disable-next-line
  const [remoteVideoViewOptions, setRemoteVideoViewOptions] = useState({
    scalingMode: "Crop", //Fit,Crop
    isMirrored: true,
  });

  const call = useCall();
  const buttonsDisabled = !(
    call?.state === "InLobby" || call?.state === "Connected"
  );
  //Server call Id for call recording
  if (call?.state === "Connected") {
    call.info.getServerCallId().then((response) => {
      props.handleServerCallId(response);
    });
  }
  if (call?.state === "Disconnected") {
    props.handleCallEndRequest();
  }
  const handleMirrorLocalVideoButton = () => {
    setLocalVideoViewOptions((prev) => {
      let message = { scalingMode: "Crop", isMirrored: !prev.isMirrored };
      return message;
    });
  };

  const handleMirrorRemoteVideoButton = () => {
    setRemoteVideoViewOptions((prev) => {
      let message = { scalingMode: "Crop", isMirrored: !prev.isMirrored };
      return message;
    });
  };

  useEffect(() => {
    if (deviceProps && deviceProps.selectedCamera) {
      if (intialCamera === null) {
        setInitialCamera(deviceProps.selectedCamera);
        setCurrentCamera(deviceProps.selectedCamera);
      }
      if (
        intialCamera &&
        currentCamera &&
        currentCamera.id !== deviceProps.selectedCamera.id
      ) {
        setCurrentCamera(deviceProps.selectedCamera);
        setLocalVideoViewOptions((prev) => {
          if (intialCamera.id === deviceProps.selectedCamera.id) {
            let message = { scalingMode: "Crop", isMirrored: true };
            sendMessage(
              {
                data: { ...message, uid: userId, type: "videoFlip" },
              },
              props.roomId
            );
            return message;
          } else if (intialCamera.id !== deviceProps.selectedCamera.id) {
            let message = { scalingMode: "Crop", isMirrored: false };
            sendMessage(
              {
                data: { ...message, uid: userId, type: "videoFlip" },
              },
              props.roomId
            );
            return message;
          } else {
            return prev;
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [deviceProps.selectedCamera]);

  useEffect(() => {
    if (roomMessage && isConnected) {
      setRemoteVideoViewOptions((prev) => {
        if (
          prev.isMirrored !== roomMessage.isMirrored &&
          roomMessage.uid !== userId &&
          roomMessage.type === "videoFlip"
        ) {
          return {
            ...prev,
            isMirrored: roomMessage.isMirrored,
          };
        }
        return prev;
      });
    }
    // eslint-disable-next-line
  }, [roomMessage, isConnected]);

  useEffect(() => {
    if (!isConnected) {
      const user_id = userId ? userId : uuidv4();
      let orderDetails = props.orderId
        ? {
            user_id: user_id,
            room_name: props.roomId,
            user_type: "guest",
            order_id: props.orderId,
          }
        : {
            user_id: user_id,
            room_name: props.roomId,
          };

      handleSocketJoinRoom(orderDetails).then((url) => {
        connect(url, props.roomId);
        setUserId(user_id);
      });
    }
    return () => {
      if (!isConnected) {
        setUserId(null);
        leaveRoom(props.roomId);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="d-flex flex-column" style={{ height: "100dvh" }}>
      <div style={{ flexGrow: 1 }}>
        {videoGalleryProps && (
          <VideoGallery
            {...videoGalleryProps}
            layout="floatingLocalVideo"
            localVideoViewOptions={localVideoViewOptions}
            remoteVideoViewOptions={remoteVideoViewOptions}
          />
        )}
      </div>
      <div>
        <div className="d-flex flex-row bg-light  justify-content-center w-100">
          {props.isAdmin && !props.isMobile ? (
            <React.Fragment>
              {raiseHandProps && (
                <RaiseHandButton
                  {...raiseHandProps}
                  disabled={buttonsDisabled}
                />
              )}
              {props.serverCallId !== undefined && (
                <RecordingButton
                  recordingButtonDisabled={props.recordingButtonDisabled}
                  recordingButtonState={props.recordingButtonState}
                  handleButtonClick={props.handleRecordingButton}
                />
              )}

              {!buttonsDisabled && (
                <ScreenShotButton
                  status={props.isScreenShot}
                  handleButtonClick={() => props.handleScreenshot(divRef)}
                  disabled={buttonsDisabled}
                />
              )}
              {screenShareProps && (
                <ScreenShareButton
                  {...screenShareProps}
                  disabled={buttonsDisabled}
                />
              )}
            </React.Fragment>
          ) : null}

          {!buttonsDisabled && (
            <ChatButton
              status={!props.isChat}
              handleButtonClick={props.handleChatWindow}
              disabled={buttonsDisabled}
            />
          )}

          {cameraProps && (
            <CameraButton
              enableDeviceSelectionMenu
              {...cameraProps}
              disabled={buttonsDisabled ?? cameraProps.disabled}
            />
          )}

          {microphoneProps && (
            <MicrophoneButton
              enableDeviceSelectionMenu
              {...microphoneProps}
              disabled={buttonsDisabled ?? microphoneProps.disabled}
            />
          )}
          {deviceProps && (
            <MirrorButton
              handleRemote={handleMirrorRemoteVideoButton}
              handleLocal={handleMirrorLocalVideoButton}
            />
          )}

          {endCallProps && (
            <EndCallButton {...endCallProps} disabled={buttonsDisabled} />
          )}
        </div>
      </div>
    </div>
  );
}

export default CallingComponents;

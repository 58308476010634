import React from "react";

export default function LogoButton({ size, width }) {
  return (
    <div className="d-flex flex-row justify-content-start align-itself-center mb-2 ">
      <img
        src="./logo512.png"
        alt="Heelio Communication Serice logo"
        width={`${size}px`}
        height={`${size}px`}
        className="rounded-start"
      />
      <div
        className="px-2 py-1 hcs-bg-primary ms-1 rounded-end p-2 text-white   shadow-sm flex-grow-1"
        style={{ height: `${size}px`, width: `${width}%` }}
      >
        <div
          className="text-white text-wrap  fw-semibold "
          style={{ fontSize: `${(size / 60) * 18}px`, letterSpacing: ".5em" }}
        >
          HEELIO
        </div>
        <div
          className="text-white  text-wrap text-uppercase"
          style={{ fontSize: `${(size / 60) * 12}px` }}
        >
          Communication Services
        </div>
      </div>
    </div>
  );
}

import React from "react";
import LoadingUI from "../CustomButton/LoadingUI";
import LogoButton from "../CustomButton/LogoButton";

const Main = (props) => {
  const homeStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  };
  return (
    <div style={homeStyle} className="hcs-bg-primary-light hcs-spacing">
      <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-items h-100 hcs-card ">
        <div>
          <LogoButton size={60} />
          <div className="card px-3 py-2 hcs-bg-primary ">
            <label
              htmlFor="displayName"
              style={{ fontSize: "14px" }}
              className="text-white mb-1 d-flex flex-row justify-content-between align-items-center"
            >
              <div> Name *</div>
              {props.displayName ? (
                <i className="bi bi-check-circle-fill text-success"></i>
              ) : (
                <i></i>
              )}
            </label>
            <input
              className="form-control form-control-sm  "
              id="displayName"
              type="text"
              placeholder="Ex: John Doe"
              maxLength={30}
              value={props.displayName}
              onChange={props.handleInput}
              required
            />
            <div
              className="pt-1 text-white-50 mb-2"
              style={{ fontSize: "12px" }}
            >
              Displayed in the communication platform
            </div>
            {props.isAdmin ? (
              <>
                <label
                  htmlFor="email"
                  style={{ fontSize: "14px" }}
                  className="text-white mb-1 d-flex flex-row justify-content-between align-items-center"
                >
                  <div> Invite Participant *</div>
                  {props.email ? (
                    <i className="bi bi-check-circle-fill text-success"></i>
                  ) : (
                    <i></i>
                  )}
                </label>
                <input
                  className="form-control form-control-sm "
                  id="email"
                  type="email"
                  placeholder="Ex: demo@domian.com"
                  value={props.email}
                  onChange={props.handleInput}
                  required
                />
                <div
                  className="pt-1 text-white-50"
                  style={{ fontSize: "12px" }}
                >
                  Enter valid email address
                </div>
              </>
            ) : (
              <>
                <label
                  htmlFor="code"
                  style={{ fontSize: "14px" }}
                  className="text-white mb-1 d-flex flex-row justify-content-between align-items-center"
                >
                  <div> Room Code *</div>
                  {props.code ? (
                    <i className="bi bi-check-circle-fill text-success"></i>
                  ) : (
                    <i></i>
                  )}
                </label>
                <input
                  className="form-control form-control-sm "
                  id="code"
                  type="text"
                  placeholder="xxxx"
                  value={props.code}
                  onChange={props.handleInput}
                  required
                />
                <div
                  className="pt-1 text-white-50"
                  style={{ fontSize: "12px" }}
                >
                  Check email for the code
                </div>
              </>
            )}
            {props.isAdmin ? (
              <>
                <label
                  htmlFor="code"
                  style={{ fontSize: "14px" }}
                  className="text-white mt-2 mb-1 d-flex flex-row justify-content-between align-items-center"
                >
                  <div> Access Key*</div>
                  {props.code ? (
                    <i className="bi bi-check-circle-fill text-success"></i>
                  ) : (
                    <div></div>
                  )}
                </label>
                <input
                  className="form-control form-control-sm mb-2 "
                  id="code"
                  type="text"
                  placeholder="xxxxxxxxxx"
                  maxLength={20}
                  value={props.code}
                  onChange={props.handleInput}
                  required
                />
              </>
            ) : null}
          </div>
          {!props.isLoading ? (
            <div className="d-flex flex-row justify-content-between py-2">
              {props.error.status ? (
                <div className="px-2 text-danger" style={{ fontSize: "12px" }}>
                  {props.error.message}
                </div>
              ) : (
                <div
                  className="px-2  text-secondary"
                  style={{ fontSize: "12px" }}
                >
                  * Required Field
                </div>
              )}
              <button
                className="btn hcs-bg-tertiary text-white  w-50 hcs-btn"
                onClick={props.handleConnect}
              >
                <div className="px-2">Connect</div>
              </button>
            </div>
          ) : props.isLoading ? (
            <div className="d-flex flex-row justify-content-between py-2">
              <div>
                <LoadingUI />
              </div>
              <button className="btn hcs-bg-tertiary text-secondary ">
                <div className="px-2">Connect</div>
              </button>
            </div>
          ) : null}
        </div>
      </div>
      <p
        className="font-monospace py-2 text-white"
        style={{ fontSize: "12px" }}
      >
        2024 - 2024 Heelio. All rights reserved
      </p>
    </div>
  );
};
export default Main;
